import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { ImageBackground } from "./styledComponents";
import Image from "./image";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";

const DeliveryCard = ({ children }) => {
  const controls = useAnimation();
  const [ref, visible] = useInView();

  const variants = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
      },
    },
  };

  useEffect(() => {
    if (visible) {
      controls.start("visible");
    }
    if (!visible) {
      controls.start("hidden");
    }
  }, [controls, visible]);

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial={"hidden"}
      animate={controls}
    >
      <ImageBackground
        tag={"div"}
        fluid={[
          `linear-gradient(90deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.95) 100%)`,
          Image().indexBg,
        ]}
        css={css`
          width: 300px;
          height: 300px;
          background-color: white;
          border-radius: 15px;
          overflow: hidden;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
          font-family: "Brandon Grotesque", sans-serif;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        {children}
      </ImageBackground>
    </motion.div>
  );
};

export default DeliveryCard;
