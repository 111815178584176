import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { ImageBackground, MainDiv } from "../components/styledComponents";
import Image from "../components/image";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import DeliveryCard from "../components/deliveryCard";
import { motion } from "framer-motion";

const Delivery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const handleClick = () => {
    const element = document.getElementById("delivery-partners");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout>
      <ImageBackground
        tag={"main"}
        fluid={[
          `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
          Image().delivery,
        ]}
        css={css`
          margin-top: calc(-47px * 3);
          padding-top: calc(47px * 3);
          height: calc(100vh - 47px * 3);
          transform: skew(0deg, -5deg);

          .content {
            height: 100%;
            transform: skew(0deg, 5deg);
            font-family: "Brandon Grotesque", sans-serif;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
              font-size: 70px;
              text-align: center;
              max-width: 1000px;
              line-height: 1;
            }

            .box {
              background-color: rgba(0, 0, 0, 0.8);
              padding: 1rem 2rem;
              border: 2px solid white;
              text-align: center;
              cursor: pointer;

              p {
                margin: 0;
              }
            }
          }
        `}
      >
        <MainDiv style={{ height: "100%", padding: "0 1rem" }}>
          <div className="content">
            <h1 style={{ marginBottom: 0 }}>NOMAD</h1>
            <h1 style={{ fontSize: "50px" }}>
              now available for takeaway and delivery
            </h1>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="box"
              onClick={handleClick}
            >
              <p>Order from one of our delivery partners</p>
            </motion.div>
          </div>
        </MainDiv>
        <ImageBackground
          tag={"div"}
          fluid={Image().pattern2}
          css={css`
            height: 25px;
          `}
        />
      </ImageBackground>
      <MainDiv
        css={css`
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          place-content: center;

          .order-btn {
            margin-top: auto;
            background-color: black;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            width: 100%;
            text-align: center;
          }

          .partner-img {
            margin: auto 0;
          }
        `}
        id={"delivery-partners"}
      >
        <DeliveryCard>
          <StaticImage
            src="../images/tigmoo.png"
            alt={""}
            transformOptions={{ fit: "cover" }}
            className="partner-img"
          />
          <div className="order-btn">Order Now</div>
        </DeliveryCard>
        <DeliveryCard>
          <StaticImage
            src="../images/afridelivery.png"
            alt={""}
            transformOptions={{ fit: "cover" }}
            className="partner-img"
          />
          <div className="order-btn">Order Now</div>
        </DeliveryCard>
        <DeliveryCard>
          <StaticImage
            src="../images/ulendoeats.png"
            alt={""}
            transformOptions={{ fit: "cover" }}
            className="partner-img"
          />
          <div className="order-btn">Order Now</div>
        </DeliveryCard>
      </MainDiv>
    </Layout>
  );
};

export default Delivery;
